import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextImage.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextLink.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/react/dist/PrismicToolbar.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/Advantages/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/ClosedRouteAvailability/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/ClosedRoutesFullpage/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/ContactForm/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/ContactUs/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/CustomBudgets/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/Footer/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/ForMoreInfoContact/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/Hero/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/Hotels/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/HowDoesItWork/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/Insurance/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/MundorailClosedRoutes/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/MundorailRoutes/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/Navigation/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/OurRoutes/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/OurTeam/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/Reasons/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/RouteHero/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/Routes/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/RoutesConditions/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/RoutesDescription/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/Survivors/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/Testimonial/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/TripRecomendations/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/slices/WhyEurope/index.tsx")